import React from 'react'
import { graphql } from 'gatsby'
import DictionaryCategory from '@/models/DictionaryCategory'
import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import Hero from '@v/algorithm-updates/hero'
import History from '@v/seo-dictionary/history'
import makeSlug from '@u/make-slug'

const Page = ({ data, pageContext }) => {
  const { category, dictionaryPage, allDatoCmsSeoDictionaryPage } = data
  const model = new DictionaryCategory(category)
  const parentPages = [
    {
      title: dictionaryPage.title,
      url: makeSlug.generic(pageContext.locale, dictionaryPage.slug)
    }
  ]

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps(allDatoCmsSeoDictionaryPage.nodes) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb
              parentPages={parentPages}
              currentPage={`${category.title} - ${dictionaryPage.title}`}
            />
          </div>
        </div>
      </div>

      <Hero
        titleBig={category.title}
        image={dictionaryPage.heroImage}
        imageAsset={dictionaryPage.heroImageAsset}
      />

      <History
        displayCategories={false}
        updates={data.terms.nodes}
        locale={pageContext.locale}
        dictionaryPageSlug={dictionaryPage.slug}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query SeoDictionaryCategoryPageQuery($locale: String, $categoryId: String) {
    category: datoCmsCategory(id: { eq: $categoryId }) {
      _allSlugLocales {
        locale
        value
      }
      slug
      title
      locale
      metaTags {
        ...MetaTags
      }
    }
    allDatoCmsSeoDictionaryPage {
      nodes {
        slug
        locale
      }
    }

    dictionaryPage: datoCmsSeoDictionaryPage(locale: { eq: $locale }) {
      locale
      title
      slug
      heroTitle
      heroImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      categoriesSectionTitle
    }

    terms: allDatoCmsSeoDictionaryTerm(
      filter: {
        locale: { eq: $locale }
        slug: { regex: "/.+/" }
        category: { id: { eq: $categoryId } }
      }
    ) {
      nodes {
        id
        locale
        slug
        title
        smallDescription
        category {
          id
          title
          slug
        }
      }
    }
  }
`
